/* --------------------------
ボタン
-------------------------- */

.c-button {
  font-family: 'ヒラギノ明朝 ProN','Hiragino Mincho ProN','Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ＭＳ Ｐ明朝','MS PMincho',sans-serif;
  display: inline-block;
  background: $color-white;
  padding: 15px 55px;
  font-size: rem(20px);
  font-weight: $bold;
  letter-spacing: 0.1em;
  cursor: pointer;
  text-align: center;
  color: $color-black;
  border:2px solid $color-red;
  min-width: 250px;
  transition: all .3s;

  &:hover {
    opacity: 0.7;
    color: $color-white;
    background-color: $color-red;    
  }

  @include view-at(sp){
    font-size: rem(16px);
  }

  &--arrow{
    position: relative;
    
    &:hover{
      &::after{
        color:$color-white;
      }
    }

    &::after{
      font-family: "Font Awesome 5 Free";
	    font-weight: 900;
      content: "\f054";
      display: block;
      font-size: rem(16px);
      position: absolute;
      top:calc(50% - 8px);
      right: 15px;
      color:$color-red;  
    }

    &-left{
      position: relative;
       &::after{
        font-family: "Font Awesome 5 Free";
  	    font-weight: 900;
        content: "\f053";
        display: block;
        font-size: rem(16px);
        position: absolute;
        top:calc(50% - 8px);
        left: 15px;
        color:$color-red;  
      } 
    }
  }

  &--disabled {
    pointer-events: none;
    background-color: $color-gray-light;
    border-color: $color-gray-light;
    color: $color-white;
  }

  

  
}
