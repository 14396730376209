/* --------------------------
見出し
-------------------------- */

.c-h1 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  padding: 150px 0;
  // background-image: url(../../images/common/image.png);

  @include view-at(sp){
    padding:40px 0 50px;
  }

  &-inner{
    background-color: rgba(255,255,255,0.6);
    padding: 60px 0;
    font-size: 2.5rem;
    font-weight: $bold;
    line-height: 1;
    letter-spacing: 0.04em;
    color:$color-black;

    @include view-at(sp){
      font-size: rem(18px);
      padding: 15px 0;
    }
  }
  
}

.c-h2 {
  color:$color-black;
  letter-spacing: 0.05em;
  font-size: rem(36px);
  line-height: 1.5;
  padding-bottom: 8px;
  font-weight: 500;
  border-bottom: 5px solid #B52323;
  margin-bottom: 50px;

  @include view-at(sp){
    font-weight: $bold;
    font-size: 1.1rem;
    margin-bottom: 25px;
    padding-bottom: 5px;
    border-bottom: 3px solid $color-red;
  }
}

.c-h3 {
  color: $color-black;
  font-size: rem(24px);
  border-left: 5px solid $color-red;;
  padding-left: 20px;
  margin-bottom: 20px;
  
  @include view-at(sp) {
    font-size: rem(18px);
  }
}

.c-h4{
  font-size: rem(20px);
  font-weight: $bold;
  margin-bottom: 20px;

  @include view-at(sp) {
    font-size: rem(16px);
    margin-bottom: 10px;
  }
}


.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}
