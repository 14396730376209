.top{
    &-slider{
        position: relative;
        &-list{
            &-item{
                img{
                    display: block;
                }
            }
        }
    }
    &-h1{
        height: 100%;
        width: 100%;
        padding: 150px;
        position: absolute;
        top: 0;
        left: 0;
        @include view-at(sp){
          
          padding: 40px 15px 50px;
        }

        &-inner{
            width: 50%;
            margin-left: auto;
            background-color: rgba(255,255,255,0.6);
            padding: 60px 0;
            font-size: 2.5rem;
            font-weight: $bold;
            line-height: 1;
            letter-spacing: 0.04em;
            color:$color-black;
            text-align: center;

            @include view-at(sp){
              font-size: rem(18px);
              padding: 15px 0;
            }
  
        }
        
    }
    &-panel{
        a{
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            height: 100%;
            padding:50px 100px;
            transition: all 0.3s ease-out;; 
            position: relative;
            z-index: 1;


            @include view-at(sp){
                padding:25px;
            }

            &:hover{
                opacity: 1;
                &::before{
                    content: '';
                    position: absolute;
                    top:0;
                    left: 0;
                    background-color: rgba(0,0,0,0.4);
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                }
                
                

                

                .top-panel-contents{
                    background-color: rgba(255,201,14,0.65);
                    color:$color-white;
                }
            }
        }
        &--about{
            background-image: url(../images/top/panel-about.jpg);
        }
        &--nokotsu{
            background-image: url(../images/top/panel-nokotsu.jpg);
        }
        &--access{
            background-image: url(../images/top/panel-access.jpg);
        }
        &--limited{
            background-image: url(../images/top/panel-limited.jpg);
        }

        &-contents{
            font-size: rem(36px);
            font-weight: $bold;
            letter-spacing: 0.04em;
            line-height: 1.2;
            text-align: center;
            height: 200px;
            padding: 50px 0;
            background-color: rgba(255,255,255,0.65);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 3;
            
            

            @include view-at(sp){
                font-size: rem(18px);
                height: 85px;
                padding:20px;
            }
        }       
        &-inner{
            display: flex;
            flex-wrap: wrap;
        }
    }

    

    &-map{
        height: 600px;
        iframe{
            width: 100%;
            height: 100%;
        }

        @include view-at(sp){
            height: 200px;
        }
    }
}