/* --------------------------
フッター
-------------------------- */

.p-fnav {
  max-width: 500px;

  @include view-at(sp){
    width: 100%;
  }

  &-list {
    margin: -10px -20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @include view-at(sp){
      flex-direction: column;
      margin: 0;
      margin-bottom: 40px;
    }

    &-item {
      flex-shrink: 1;
      padding: 10px 20px;
      

      @include view-at(sp){
        padding:0;
        width: 100%;
        background-color: rgba(62, 62, 62, 0.3);
        border-bottom:1px solid $color-white;
      }

      &::before{
        font-family: "Font Awesome 5 Free";
	      font-weight: 900;
        content: "\f054";
        display: inline-block;
        font-size: rem(16px);
        color:$color-red;
        position: relative;
        top:calc(50% - 8px);
        left: 0;

        @include view-at(sp){
          content: none;
        }
      }

      &-link {
        display: inline-block;
        letter-spacing: .04em;
        position: relative;

        @include view-at(sp){
          display: block;
          color:$color-white;
          padding:20px;          
          font-size: 0.9rem;
        }
        @include view-at(sp) {
          &--sub {
            &::after {
              transition: all .1s;
              content: '';
              display: block;
              position: absolute;
              right: 20px;
              top: 20px;
              color: $color-white;
              transform: rotate(45deg);
              border-right: 1px solid $color-white;
              border-bottom: 1px solid $color-white;
              width: 10px;
              height: 10px;
            }

            &.changed {
              &::after {
                top: 25px;
                transform: rotate(225deg);
              }
            }
          }
        }
      }
    }
  }
  &-sub {    
    position: relative;
    top: 0;
    overflow: visible;
    height: auto;
    display: none;
    background-color:transparent;

    &-section{
      max-width: $contents-width;
      width: 100%;
      margin: 0 auto;
      padding:0 20px;

      @include view-at(sp){
        padding:0;
      }
    }

    &-list {
      display: block;

      &-item {
        padding: 0;
        border-top: 1px solid $color-white;

        

        a {
            display: block;
            padding: 15px 30px;
            color:$color-white;   
            
            @include view-at(sp){
               font-size: 0.9rem;
            }
          }
        }
      }
    }
  
}
