/* --------------------------
ハンバーガーメニューアイコン
-------------------------- */

.c-menu {
  display: none;

  @include view-at(sp) {
    display: block;
    width: 30px;
    height: 30px;
    position: relative;
  }

  &-trigger,
  &-trigger span {
    display: inline-block;
    transition: all 0.2s;
    box-sizing: border-box;
  }

  &-trigger {
    width: 30px;
    height: 18px;
    position: relative;

    &-label {
      font-family: 'Helvetica','Arial','Hiragino Kaku Gothic ProN','ヒラギノ角ゴ ProN W3','メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic' ,sans-serif;
      display: block;
      font-size: 8px;
      letter-spacing: 1.1px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      text-align: center;
      width: 100%;
      background-color: transparent;
      height: auto;
      color: $color-red;
    }
  }

  &-trigger span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $color-red;
  }

  &-trigger span:nth-of-type(1) {
    top: 0;
  }

  &-trigger span:nth-of-type(2) {
    top: 8px;
  }

  &-trigger span:nth-of-type(3) {
    bottom: 0;
  }

  &-trigger.active span:nth-of-type(1) {
    transform: translateY(8px) rotate(-45deg);
  }

  &-trigger.active span:nth-of-type(2) {
    opacity: 0;
  }

  &-trigger.active span:nth-of-type(3) {
    transform: translateY(-8px) rotate(45deg);
  }
}
