
.entry-content,.edit-post-visual-editor {
  h2 {
    @extend .c-h2;
  }
  h3 {
    @extend .c-h3;
  }

  h4 {
    @extend .c-h4;
  }

  ul {
    @extend .c-list;
    @extend .c-list--point;
  }

  ol {
    @extend .c-list;
    @extend .c-list--order;
  }

  p {
    line-height: 1.625em;
  }

  a{
    
    text-decoration: underline;
    
  }
}
// .wp-block-file__button,
// .wp-block-button__link {
//   @extend .c-button;
// }


.wp-block-button .wp-block-button__link { 
  padding: 15px 55px;
  font-size: rem(20px);
  text-align: center;
  min-width: 250px;
  letter-spacing: 0.1em;
  text-decoration: none !important;
  transition:none!important;

  @include view-at(sp){
    text-decoration: none !important;   
    transition:none!important;
  }
}




.wp-block-embed__wrapper{
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  iframe{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    }
}


.wp-block {
  max-width: 100%;
}

strong{
  font-weight: $bold;
}

.wp-block-image figcaption{
  margin-top: .5em;
  margin-bottom: 1em;
  text-align: center;
  font-size: 0.75rem;
}

.wp-block-image,
.wp-block-columns,
.wp-block-media-text,
.wp-block-table,
.wp-block-cover,
.wp-block-quote,
.wp-block-separator,
.wp-block-button {
  margin: nth($space, 3) 0;
}




.wp-block-table {
  margin: nth($space, 3) 0;
  border: 1px solid #ccc;
}

.wp-block-table tr{
  padding:10px;
  border-bottom:1px solid #ccc;

  &:last-child{
    border-bottom:none;
  }
}



.wp-block-table td{
  padding:10px;
  border-right:1px solid #ccc;
}
.wp-block-quote {
  padding: 20px;
  background-color: $color-gray-snow;
}

.wp-block-image {
  &::after {
    content: "";
    clear: both;
    font-size: 0;
    height: 0;
    display: block;
    visibility: hidden;
  }
}

.wp-block-table {
  &.aligncenter,
  &.alignleft,
  &.alignright {
    width: 100%;
  }
}

@include view-at(tab) {
  .wp-block-columns {
    flex-wrap: nowrap;
  }
}

@include view-at(sp) {
  .wp-block-column + .wp-block-column {
    margin-top: nth($space, 2);
  }
}


