/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  margin-top: 25px;
  

  @include view-at(sp) {
    font-size: 10px;
  }

  &-list {
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    font-size: rem(14px);
    

    li {
      letter-spacing: 0.04em;
      &::after {
        content: '>';
        padding: 0 1em;
      }
      
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
}
