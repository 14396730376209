
.p-card {
  &-date{
    font-size: rem(20px);
    letter-spacing: 0.04em;
    margin-bottom: 20px;

    @include view-at(sp){
      font-size: rem(16px);
      margin-bottom: 10px;
      line-height: 2;
    }
  }

  &-image{
    position: relative;
    margin-bottom: 10px;

    img{
      display: block;
      object-fit: cover;
      width: 100%;
      height: 175px;
    }
  }

  &-label{
    position: absolute;
    top:0;
    left: 0;
  }

  p{
    line-height: 1.4;
  }
  &-movie{
      position: relative;
      height: 0;
      padding-bottom:56.25%;
      overflow: hidden;
      margin-bottom: 10px;

    iframe{
        
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;

        


    }
    
  }

}