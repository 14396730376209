/* --------------------------
フッター
-------------------------- */

.p-footer {
  background:$color-gray-light;

  @include view-at(sp){
    margin-bottom: 60px;
  }

  &-section{
    max-width: $contents-width;
    width: 100%;
    padding:0 20px;
    margin: 0 auto;

    @include view-at(sp){
      padding:0;

    }
  }

  &-contents{
    padding:80px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include view-at(sp){
      padding:0 0 40px;
      flex-direction: column-reverse;
    }
  }

  &-info{
    flex-shrink: 0;

    @include view-at(sp){
      text-align: center;
    }

    &-name{
      font-size: rem(36px);
      margin-bottom: 0.5em;
      font-weight: $bold;
      letter-spacing: 0.05em;

      @include view-at(sp){
        font-size: rem(18px);
      }
    }
  }
  
  &-copyright {
    font-size: rem(16px);
    text-align: center;
    background-color: $color-red;
    color: $color-white;
    padding: 55px;
    line-height: 1;

    @include view-at(sp){
      font-size: rem(12px);
      padding:20px 0;
    }
  }
}
