
.wp-pagenavi{
  text-align: center;
  
  .pages{
    display: none;
  }
  span, a{
    /*数字部分の共通CSS　大きさなど*/
    display: inline-block;
    margin: 0 5px;
    width: 48px;
    padding:15px 0;
    color: $color-red;
    text-decoration: none;
    border: 1px solid $color-red;


    &.current, &:hover{
      /*現在のページ*/
      color: $color-white;
      background:$color-red;
    }

    &.nextpostslink, &.previouspostslink {
      display: none;
      
      // border: 1px solid $color-red;

      // &:hover {
      //   color: $color-white;
      //   background:$color-red;
      // }
    }
  }
}
