/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
  @include view-at(sp){
    display: none;
  }
  &-list {
    display: flex;
    max-width: $contents-width;
    margin: 0 auto;

    @include view-at(sp) {
      flex-direction: column;
      background-color: rgba(62, 62, 62, 0.3);
      
    }

    &-item {
      flex-grow: 1;
      padding:0;
      

      &-link {
        display: block;
        font-size: 1.1rem;
        letter-spacing: 0.04em;
        text-align: center;
        padding: 22px 0 22px;
        position: relative;
        
        
        

        @include view-at(sp) {
          padding: 15px;
          border-bottom: 1px solid $color-white;
          text-align: left;
          color:$color-white;
          font-size: 0.9rem;
        }

        &:hover {
          color: $color-red;
        }

        
        
        @include view-at(sp) {
          &--sub {
            &::after {
              transition: all .1s;
              content: '';
              display: block;
              position: absolute;
              right: 20px;
              top: 20px;
              color: $color-white;
              transform: rotate(45deg);
              border-right: 1px solid $color-white;
              border-bottom: 1px solid $color-white;
              width: 10px;
              height: 10px;
            }

            &.changed {
              &::after {
                top: 25px;
                transform: rotate(225deg);
              }
            }
          }
        }
      }

      &:hover {
        >.p-gnav-sub {
          height: 60px;

          @include view-at(sp) {
            height: auto;
          }
        }
      }

      &.current {
        .p-gnav-list-item-link {
          color:$color-red;
          @include view-at(sp) {
            background-color: $color-red;
            color: $color-white;
          }

          &::before {
            width: 100%;
          }
        }
      }
    }
  }

  &-sub {
    background-color: $color-gray-light;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    @include view-at(pc) {
      transition: height .2s;
    }

    @include view-at(sp) {
      position: relative;
      top: 0;
      overflow: visible;
      height: auto;
      display: none;
      background-color:transparent;
    }

    &-section{
      max-width: $contents-width;
      width: 100%;
      margin: 0 auto;
      padding:0 20px;

      @include view-at(sp){
        padding:0;
      }
    }

    &-list {
      display: flex;

      @include view-at(sp) {
        display: block;
      }

      &-item {
        padding: 20px 40px 20px 0;
        

        @include view-at(sp) {
          padding: 0;
          border-bottom: 1px solid $color-white;
          
        }

        a {
          color: $color-black;
          font-size: 0.9rem;

          @include view-at(sp) {
            display: block;
            padding: 15px 30px;
            color:$color-white;
            
          }
        }
      }
    }
  }
}
