/* --------------------------
テーブル
-------------------------- */

.c-table {
  
  width: 100%;
  

  @include view-at(sp) {
    font-size: rem(16px);
    line-height: 1.5;
  }

  tbody {
    tr {
      border-bottom: 1px solid $color-gray;
      

      @include view-at(sp) {
        border-bottom: 0;
        width: 100%;
      }
    }
    th {
      padding: 20px 30px;
      font-weight: $normal;
      width: 300px;
      line-height: 1.5;
      // background-color: $color-gray-snow;
      // vertical-align: top;
      
      @include view-at(sp) {
        display: block;
        width: 100%;
        text-align: left;
        padding: 10px 15px;
      }
    }
    td {
      padding: 20px 40px;

      @include view-at(sp) {
        display: block;
        width: 100%;
        border-bottom: 1px solid $color-gray-light;
        padding: 10px 15px;
      }
    }
  }

  &--narrow {
    border-color: $color-gray-light;
    tbody {
      tr {
        border-color: $color-gray-light;
      }
      th {
        padding: 8px 10px;
        line-height: 1.5;
        font-weight: normal;
        width: 120px;
        font-weight: $bold;
        text-align: right;

        @include view-at(sp) {
          padding: 8px;
          width: 80px;
        }
      }
      td {
        padding: 8px 10px 8px 20px;
        line-height: 1.5;

        @include view-at(sp) {
          padding: 8px 10px 8px 15px;
        }
      }
    }
  }
}
