html {
  font-size: 18px;
}

body {
  font-family: 'ヒラギノ明朝 ProN','Hiragino Mincho ProN','Yu Mincho Light','YuMincho','Yu Mincho','游明朝体','ＭＳ Ｐ明朝','MS PMincho',sans-serif;
  font-weight: 300;
  color: $color-black;
  
}

a {
  color: $color-black;
  text-decoration: none;

  &:hover {
    opacity: 0.6;

    img {
      opacity: 0.6;
    }
  }
}

p {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 1em; 
  letter-spacing: 0.04em;

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    font-size: rem(16px);
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  padding-top: 168px;

  @include view-at(sp){
    padding-top: 60px;
  }
}
