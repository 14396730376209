/* --------------------------
ヘッダー
-------------------------- */

.p-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: $color-white;

  &-inner {
    max-width: $contents-width;
    width: 100%;
    margin: 0 auto;
    padding: 30px 20px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include view-at(sp) {
      padding: 15px 20px 15px;
    }
  }

  &-logo {
    a {
      display: inline-block;
      font-size: rem(36px);
      font-weight: $bold;
      letter-spacing: 0.04em;

      @include view-at(sp){
        font-size: rem(18px);
      }
    }
  }

  &-sns{
    margin-top: 10px;
    &-list{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: -8px;

      &-item{
        padding:8px;

        a{
          display: block;
        }
      }

    }
    
    
  }
}
