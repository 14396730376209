/* --------------------------
エクストラナビ（Gナビとは別にヘッダー内に表示するナビゲーション）
-------------------------- */

.p-exnav {
  @include view-at(sp){
    display: none;
  }
  
  &-tel{
    font-size: rem(24px);
    letter-spacing: 0.04em;
    font-weight: $bold;
  }

  
}
