/* --------------------------
リンク
-------------------------- */

.c-link {
  color: $color-black;

  &--block {
    display: block;
    text-decoration: none;

    img {
      display: block;
    }
  }

  &--border {
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
      opacity: 0.7;
    }
  }

  &--none {
    text-decoration: none;
  }
}
