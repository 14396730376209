.about{
    &-h1{
        background-image: url(../images/about/mv.jpg);
    }

    &-map{
        height: 310px;

        iframe{
            width: 100%;
            height: 100%;
        }
    }

    &-movie{
        position:relative;
        width:100%;
        padding-top:56.25%;

        iframe{
            
            position:absolute;
            top:0;
            right:0;
            width:100%;
            height:100%;

        }
    }
}