/* --------------------------
color
-------------------------- */

$color-white: #fff;
$color-black: #000;
$color-red: #B52323;
$color-yellow: #FFC90E;
$color-green: #15A23F;
$color-purple: #6E1C9A;

$color-gray: #a2a2a2;
$color-gray-light: #F4F4F4;
$color-gray-dark: #696969;
$color-gray-snow: #eeeeee;

/* --------------------------
spacing
-------------------------- */
$space: 80px, 40px, 30px, 20px;
$space-tab: 60px, 40px, 20px, 10px;
$space-sp: 40px, 30px, 20px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 28px, 20px, 18px, 16px, 14px, 12px;
$line-height: 50px, 36px, 32px, 28px, 22px, 18px;

$font-size-sp: 19px, 18px, 16px, 14px, 12px, 10px;
$line-height-sp: 40px, 32px, 30px, 26px, 24px, 16px;

$bold: 600;
$normal: 300;

/* --------------------------
contents width
-------------------------- */
$contents-width: 1180px;
$contents-narrow-width: 1000px;
$contents-padding: 20px;
$contents-padding-sp: 25px;

/* --------------------------
z-index order
-------------------------- */
$z: (contents, arrow, gnav, leftbar, header, footer, modal);
