.p-category {
  margin: 100px 0;

  @include view-at(sp){
    margin: 50px 0;
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -10px;

    @include view-at(sp){
      flex-wrap: wrap;
      margin: -5px;
    }

    &-item {
      padding: 0 10px;

      @include view-at(sp){
        width: calc(100% / 3);
        flex-grow: 0;
        padding: 5px;
      }

      a {
        display: inline-block;
        letter-spacing: 0.04em;
        font-weight: $bold;
        padding: 20px;
        text-align: center;
        min-width: 150px;

        @include view-at(sp){
          min-width: auto;
          width: 100%;
          font-size: rem(14px);
          padding: 10px;
        }
      }
    }
  }

  &--all {
    border: 2px solid $color-red;
  }

  &--red {
    background-color: $color-red;
    color: $color-white;
  }

  &--yellow {
    background-color: $color-yellow;
    color: $color-white;
  }

  &--green {
    background-color: $color-green;
    color: $color-white;
  }

  &--purple {
    background-color: $color-purple;
    color: $color-white;
  }
}
