/* --------------------------
フッター固定ボタン
-------------------------- */

.p-fixedmenu{
  display: none;

  @include view-at(sp){
    display: block;
    position: fixed;
    bottom:0;
    left:0;
    width:100%;
    background: $color-red;
    border:1px solid $color-white;
    z-index: 10000;
  }

  &-list{
    display: flex;
    align-items: center;

    &-item{
      width: 100%;
      border-right:1px solid $color-white;

      &:last-child{
        border-right:none;
      }

      a{
        display: block;
        font-size: 1.7rem;
        color:$color-white;
        text-align: center;
        padding:15px 10px;
        
      }
    }
  }
}