/* --------------------------
ラベル
-------------------------- */

.c-label {
  display: inline-block;
  padding: 5px 15px;
  line-height: 1;
  background-color: $color-gray;
  color: $color-white;
  font-size: rem(16px);
  letter-spacing: 0.04em;

  &--red{
    background-color: $color-red;
  }
  &--yellow{
    background-color: $color-yellow;
  }
  &--green{
    background-color: $color-green;
  }
  &--purple{
    background-color: $color-purple;
  }
}
