
.p-caption {
  position: absolute;
  bottom: 6px;
  right: 6px;
  text-align: right;
  color: $color-white;
  text-shadow: 0 0 6px rgba(0,0,0,1), 0 0 6px rgba(0,0,0,1), 0 0 6px rgba(0,0,0,1), 0 0 6px rgba(0,0,0,1), 0 0 6px rgba(0,0,0,1), 0 0 6px rgba(0,0,0,1), 0 0 6px rgba(0,0,0,1), 0 0 6px rgba(0,0,0,1);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1.4;

  @include view-at(sp) {
    font-size: 10px;
  }
}